import * as yup from 'yup'
const INPUT_LABEL_PROPS={
  shrink: true,
  style: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "22px",
    color:"black",
    bottom:15
  }
}
const INPUT_PLACE_HOLDER_PROS={
  fontSize: "14px",
  fontStyle: 'italic',
  fontFamily: 'Lato',
  fontWeight: 400,
}
export const formLogin =(t)=>( {
  validationSchema: {
    email: yup
        .string()
        .trim(t('_TKEY_FORM_VALIDATION_TRIM'))
        .email(t('_TKEY_FORM_VALIDATION_EMAIL', { ns: 'form_login' }))
        .strict(true)
        .required(t('_TKEY_FORM_REQUIRED_EMAIL', { ns: 'form_login' }))
        .test(
            'test_lower_case',
            t('_TKEY_FORM_VALIDATION_LOWER_CASE', { ns: 'form_login' }),
            (value, context) => {
              return value && value === value.toLowerCase();
            }
        ),
    password: yup
        .string()
        .required(t('_TKEY_FORM_REQUIRED_PASSWORD', { ns: 'form_login' }))
        .min(10, t('_TKEY_FORM_REQUIRED_NRN_MIN', { ns: 'form_login' }))
        .max(11, t('_TKEY_FORM_REQUIRED_NRN_MAX', { ns: 'form_login' }))
        .test('Digits only', t('_TKEY_FORM_REQUIRED_ONLY_NUMBER', { ns: 'form_login' }), (value) => /^\d+$/.test(value))
    ,

  },
  initialValues: {
    email: '',
    password: '',
  },
  grid: {
    spacing: 3,
    otherProps: null,
  },
  model: [
    {
      fullWidth: true,
      size: 'small',
      id: 'email',
      name: 'email',
      label: t('_TKEY_FORM_EMAIL', { ns: 'form_login' }),
      component: 'TextField',
      type: 'text',
      variant:'standard',
      placeholder:t('_TKEY_FORM_PLACE_HOLDER_EMAIL', { ns: 'form_login' }),
      inputLabelProps: INPUT_LABEL_PROPS ,
      otherProps: {
        sx: {
          "& input::placeholder": INPUT_PLACE_HOLDER_PROS,
          mb: 2,
        },
      },
      grid: {
        otherProps: {
          xs: 12,
        },
      },
    },
    {
      fullWidth: true,
      size: 'small',
      id: 'password',
      name: 'password',
      label: t('_TKEY_FORM_PASSWORD', { ns: 'form_login' }),
      component: 'TextField',
      type: 'password',
      variant:'standard',
      inputLabelProps: INPUT_LABEL_PROPS ,
      placeholder: t('_TKEY_FORM_PLACE_HOLDER_PASSWORD', { ns: 'form_login' }),
      otherProps: {
        sx: {
          "& input::placeholder": INPUT_PLACE_HOLDER_PROS,
          mb: 2,
        },
      },
      grid: {
        otherProps: {
          xs: 12,
        },
      },

    }
  ],
  submitButton: {
    grid:{
      otherProps: {
        display: 'flex',
        justifyContent:'center'
      },
    },
    otherProps: {
      sx:{
        maxWidth: '180px',
        borderRadius: "50px",
        textTransform: "none"
      }
    },
    isValidating: false,
    loadingIndicator: t('_TKEY_FORM_VALIDATION_BUTTON_NAME_LOADING', { ns: 'form_login' }),
    name: t('_TKEY_FORM_VALIDATION_BUTTON_NAME', { ns: 'form_login' }),
    variant: 'contained',
    fullWidth: true,
  },
})
export const changeStateSubmitButton = (form, setForm) => {
  form.submitButton.isValidating = !form.submitButton.isValidating
  setForm({ ...form })
}
