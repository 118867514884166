import * as yup from 'yup'
import "yup-phone";

const INPUT_LABEL_PROPS={
  shrink: true,
  style: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "22px",
    color:"black"
  }
}
const INPUT_PLACE_HOLDER_PROS={
  fontSize: "14px",
  fontStyle: 'italic',
  fontFamily: 'Lato',
  fontWeight: 400,
}

export const formResetPassword =(t)=>( {
  validationSchema: {
    nrn: yup
        .string()
        .required(t('_TKEY_FORM_REQUIRED_NRN', { ns: 'reset-password' }))
        .min(10, t('_TKEY_FORM_REQUIRED_NRN_MIN', { ns: 'reset-password' }))
        .max(11, t('_TKEY_FORM_REQUIRED_NRN_MAX', { ns: 'reset-password' }))
        .test('Digits only', t('_TKEY_FORM_REQUIRED_ONLY_NUMBER', { ns: 'reset-password' }), (value) => /^\d+$/.test(value))
  },
  initialValues: {
    nrn: '',
  },
  grid: {
    otherProps: null,
  },
  model: [
    {
      fullWidth: true,
      size: 'small',
      id: 'nrn',
      name: 'nrn',
      label: t('_TKEY_FORM_NRN', { ns: 'reset-password' }),
      component: 'TextField',
      type: 'text',
      variant:'standard',
      inputLabelProps: INPUT_LABEL_PROPS ,
      placeholder:t('_TKEY_FORM_PLACE_HOLDER_NRN', { ns: 'reset-password' }),
      otherProps: {
        sx: {
          "& input::placeholder": INPUT_PLACE_HOLDER_PROS,
          mb: 2,
        },
      },
      grid: {
        otherProps: {
          xs: 12,
        },
      },
    }
  ],
  submitButton: {
    grid:{
      otherProps: {
        display: 'flex',
        justifyContent:'center',
        mt:5
      },
    },
    otherProps: {
      sx:{
        maxWidth: '180px',
        borderRadius: "50px",
        textTransform: "none"
      }
    },
    isValidating: false,
    loadingIndicator: t('_TKEY_FORM_VALIDATION_BUTTON_NAME_LOADING', { ns: 'reset-password' }),
    name: t('_TKEY_FORM_VALIDATION_BUTTON_NAME', { ns: 'reset-password' }),
    variant: 'contained',
    fullWidth: true,
  },
})
export const changeStateSubmitButton = (form, setForm) => {
  form.submitButton.isValidating = !form.submitButton.isValidating
  setForm({ ...form })
}
