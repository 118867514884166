import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Grid} from "@mui/material";
import {useRouter} from "next/router";
import {changeStateSubmitButton, formLogin} from "../../utils/form-login.utils";
import LayoutAnonymousCandidate from "../../components/layout/layout-anonymous-candidate/layout-anonymous-candidate";
import FormMaker from "../../components/form/form-maker/form-maker.component";
import Link from "@mui/material/Link";
import {getSession, signIn} from "next-auth/react";
import {Alert} from "@mui/material";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import nextI18NextConfig from "../../next-i18next.config";
import {Trans, useTranslation} from "next-i18next";
import {defaultComponent} from "../../utils/i8next/component";
import Seo from "../../components/seo/seo.component";
import DialogNumRegistreReset
    from "../../components/dialog/dialog-num-registre-reset/dialog-num-registre-reset.component";
import Image from "next/legacy/image";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";

export default function Index() {
    const {t} = useTranslation(['login']);
    const {t:tContact} = useTranslation(['form_login']);
    const [form, setForm] = useState(formLogin(tContact))
    const { executeRecaptcha } = useGoogleReCaptcha();

    const router = useRouter()
    const handleSubmitForm = async (values) => {
        changeStateSubmitButton(form, setForm);
        const token = await executeRecaptcha("register_page");
        const req = await axios.post(process.env.NEXT_PUBLIC_ASSETS_URL + '/api/recaptcha-verify', {token:token});
        if (req.data.response === 'pass') {
            await signIn('credentials', values);
        }
        changeStateSubmitButton(form, setForm);
    };
    return (
                <>
                    <Seo title={t('_TKEY_SEO_TITLE', { ns: 'form_login' })} description={t('_TKEY_SEO_DESCRIPTION', { ns: 'aide' })} />
                    <Box sx={{mt:{xs: 5,sm: 5,md:7,lg:7,xl:7}}} >
                        <Box sx={{mb:5}} >
                            <Typography variant="h1" component="h1" gutterBottom  sx={{
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: {xs: "24px",sm:"24px",md:"24px",lg:"24px",xl:"24px"},
                                lineHeight: "10px",
                                textAlign: "center"
                            }}>
                                {t('_TKEY_CONTENT_1', { ns: 'login' })}
                            </Typography>
                        </Box>
                        <Grid container spacing={3} alignItems={'center'}>
                            <Grid item xs={12} sm={12} md={3}>
                                <div>
                                    <Image src={'/img/login/login-page.png'}
                                           alt={'login-image'}
                                           layout="responsive"
                                           width={500}
                                           height={236}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={9}>
                                <div>

                                    <Box sx={{
                                        fontFamily: 'Lato',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '18px',
                                        lineHeight: '22px',
                                        textAlign: 'left',
                                        letterSpacing: '0.05em',
                                    }}>
                                        {<Trans
                                            i18nKey="login:_TKEY_CONTENT_2"
                                            components={defaultComponent()}
                                        />}
                                        <br/>
                                        <ul>
                                            <li>{t('_TKEY_CONTENT_3', { ns: 'login' })}</li>
                                            <li>{t('_TKEY_CONTENT_4', { ns: 'login' })}</li>
                                            <li>{t('_TKEY_CONTENT_5', { ns: 'login' })}</li>
                                        </ul>
                                        {t('_TKEY_CONTENT_6', { ns: 'login' })}
                                    </Box>
                                </div>
                            </Grid>

                        </Grid>

                        <Box sx={
                            {
                                mx: {xs: "24px",sm:"24px",md:"10rem"},
                                mt:5
                            }
                        }>
                            {
                                router.query.error ==="SessionRequired" && <>
                                    <Alert severity="error" sx={{
                                        mb:5
                                    }}> <Trans
                                        i18nKey="login:_TKEY_CONTENT_13"
                                        components={defaultComponent()}
                                    /></Alert>
                                </>
                            }
                            {
                                router.query.error ==="CredentialsSignin" && <>
                                    <Alert severity="error" sx={{
                                        mb:5
                                    }}>
                                        <Trans
                                            i18nKey="login:_TKEY_CONTENT_7"
                                            components={defaultComponent()}
                                        /><Link href={'/contact'}>{t('_TKEY_CONTENT_8', { ns: 'login' })}</Link></Alert>
                                </>
                            }
                            <FormMaker form={form} handleSubmitForm={handleSubmitForm} />
                            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center' }} >
                                <div id="recaptcha-register" />
                            </Box>
                        </Box>
                        <Box >
                            <Box sx={{
                                fontFamily: 'Lato',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '18px',
                                lineHeight: '22px',
                                textAlign: 'left',
                                letterSpacing: '0.05em',
                                mt:7
                            }}>
                                {t('_TKEY_CONTENT_9', { ns: 'login' })}
                            </Box>
                            <Box mb={5}>
                                <DialogNumRegistreReset />
                            </Box>
                        </Box>
                        <Box sx={{
                            fontFamily: 'Lato',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '18px',
                            lineHeight: '22px',
                            textAlign: 'left',
                            letterSpacing: '0.05em',
                            mb: 1
                        }}>{t('_TKEY_CONTENT_11', { ns: 'login' })}
                        </Box>
                        <Box sx={{
                            fontFamily: 'Lato',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '18px',
                            lineHeight: '22px',
                            textAlign: 'left',
                            letterSpacing: '0.05em',
                        }}>
                            {<Trans
                                i18nKey="login:_TKEY_CONTENT_12"
                                components={defaultComponent()}
                            />}
                        </Box>
                    </Box>
                </>
    );
}
Index.getLayout = function getLayout(page) {
    return (
        <LayoutAnonymousCandidate>
            <GoogleReCaptchaProvider
                language={process.env.NEXT_PUBLIC_WEB_SITE_LANGUAGE}
                reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SECRET}
                scriptProps={{
                    async: false,
                    defer: false,
                    appendTo: "head",
                    nonce: undefined,
                }}
                container={{
                    element: "recaptcha-register",
                }}
            >
            {page}
            </GoogleReCaptchaProvider>
        </LayoutAnonymousCandidate>
    )
}

export async function getServerSideProps(ctx) {
    const session  = await getSession(ctx)
    if(session){
        if(session.user.statut === 'chronosreussi'){
            return {
                redirect: {
                    permanent: false,
                    destination: "/mon-espace-apprenant/attestation",
                },
                props: {
                    ...(await serverSideTranslations(process.env.WEB_SITE_LANGUAGE, [ 'reset-password','form_login','login','menu','common','footer'],nextI18NextConfig)),
                },
            };
        }
        if(session.user.statut === 'enAttente' && session.user.isSession){
            return {
                redirect: {
                    permanent: false,
                    destination: "/mon-espace-apprenant/inscription",
                },
                props: {
                    ...(await serverSideTranslations(process.env.WEB_SITE_LANGUAGE, [  'reset-password','form_login','login','menu','common','footer'],nextI18NextConfig)),
                },
            };
        }
        return {
            redirect: {
                permanent: false,
                destination: "/mon-espace-apprenant",
            },
            props: {
                ...(await serverSideTranslations(process.env.WEB_SITE_LANGUAGE, [ 'reset-password','form_login','login','menu','common','footer'],nextI18NextConfig)),
            },
        };
    }else{
        return {
            props: {
                ...(await serverSideTranslations(process.env.WEB_SITE_LANGUAGE, ['reset-password','form_login','login', 'menu','common','footer'],nextI18NextConfig)),
            },
        };
    }
}