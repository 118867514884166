import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'

import LoadingButton from '@mui/lab/LoadingButton'
import {
  FormControlLabel,
  Grid,
  MenuItem,
  RadioGroup,
  TextField,
  Radio,
  FormHelperText,
  FormControl
} from '@mui/material'

const FormMaker = ({ form, handleSubmitForm,children }) => {
  const validationSchema = yup.object(form.validationSchema)
  const formik = useFormik({
    initialValues: form.initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmitForm(values)
    },
  })
  const handleChangeSelect = (event, prop) => {
    formik.setFieldValue(prop, event.target.value, false)
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={form.grid.spacing} {...form.grid.otherProps}>
        {form.model.map((value, index) => {
          if(value.component === 'radio'){
            return(
                <Grid key={index} item {...value.grid.otherProps}>
                  <FormControl error={formik.touched[value.name] && Boolean(formik.errors[value.name])} variant="standard">
                    <RadioGroup
                        aria-labelledby={value.name}
                        name={value.name}
                        value={formik.values[value.value]}
                        onChange={formik.handleChange}
                    >
                      {value.menuItems &&
                          value.menuItems.map((menuItem, indexMenuItem) => {
                             return (<FormControlLabel key={indexMenuItem} value={menuItem.value} control={<Radio />} label={menuItem.label} />)
                          })
                      }
                    </RadioGroup>
                    <FormHelperText>{formik.touched[value.name] && formik.errors[value.name]}</FormHelperText>

                  </FormControl>
                </Grid>
                )
          }
          if (value.component === 'TextArea') {
            return (
                <Grid key={index} item {...value.grid.otherProps}>
                  <TextField
                      {...value.otherProps}
                      fullWidth={value.fullWidth}
                      size={value.size}
                      id={value.id}
                      name={value.name}
                      label={value.label}
                      type={value.type}
                      variant={value.variant}
                      multiline={true}
                      rows={value.textAreaRows}
                      value={formik.values[value.name]}
                      onChange={formik.handleChange}
                      placeholder={value.placeholder}
                      InputLabelProps={value.inputLabelProps}
                      error={
                          formik.touched[value.name] &&
                          Boolean(formik.errors[value.name])
                      }
                      helperText={
                          formik.touched[value.name] && formik.errors[value.name]
                      }
                  />
                </Grid>
            )
          }
          if (value.component === 'TextField') {
            return (
              <Grid key={index} item {...value.grid.otherProps}>
                <TextField
                  {...value.otherProps}
                  fullWidth={value.fullWidth}
                  variant={value.variant}
                  size={value.size}
                  id={value.id}
                  name={value.name}
                  label={value.label}
                  type={value.type}
                  value={formik.values[value.name]}
                  onChange={formik.handleChange}
                  placeholder={value.placeholder}
                  error={
                    formik.touched[value.name] &&
                    Boolean(formik.errors[value.name])
                  }
                  InputLabelProps={value.inputLabelProps}
                  helperText={
                    formik.touched[value.name] && formik.errors[value.name]
                  }
                />
              </Grid>
            )
          }
          if (value.component === 'Select') {
            return (
              <Grid key={index} item {...value.grid.otherProps}>
                <TextField
                  {...value.otherProps}
                  fullWidth={value.fullWidth}
                  size={value.size}
                  id={value.id}
                  name={value.name}
                  label={value.label}
                  value={formik.values[value.name]}
                  onChange={(e) => handleChangeSelect(e, value.name)}
                  select
                  error={
                    formik.touched[value.name] &&
                    Boolean(formik.errors[value.name])
                  }
                  helperText={
                    formik.touched[value.name] && formik.errors[value.name]
                  }
                >
                  {value.menuItems &&
                    value.menuItems.map((menuItem, indexMenuItem) => {
                      return (
                        <MenuItem key={indexMenuItem} value={menuItem.value}>
                          {menuItem.name}
                        </MenuItem>
                      )
                    })}
                </TextField>
              </Grid>
            )
          }
        })}
        <Grid item xs={12}>
          {
            children
          }
        </Grid>
        <Grid item xs={12} {...form.submitButton.grid.otherProps} >
          <LoadingButton
            fullWidth={form.submitButton.fullWidth}
            {...form.submitButton.otherProps}
            type={'submit'}
            loading={form.submitButton.isValidating}
            loadingIndicator={form.submitButton.loadingIndicator}
            variant={form.submitButton.variant}
          >
            {form.submitButton.name}
          </LoadingButton>
        </Grid>
      </Grid>

    </form>
  )
}
export default FormMaker
