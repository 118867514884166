import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import {Alert, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import Box from "@mui/material/Box";
import {Trans, useTranslation} from "next-i18next";
import {defaultComponent} from "../../../utils/i8next/component";
import {changeStateSubmitButton } from "../../../utils/form-contact.utils";
import {formResetPassword} from "../../../utils/form-reset-password.utils";
import axios from "axios";
import SuccessMessage from "../../message/success-message/success-message.component";
import FormMaker from "../../form/form-maker/form-maker.component";
import {useEffect} from "react";

function CloseIcon() {
  return null;
}
const DialogNumRegistreReset = () => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation(['reset-password']);
  const [form, setForm] = React.useState(formResetPassword(t))
  const [successMessages, setSuccessMessages] = React.useState('')
  const [alertMessages, setAlertMessages] = React.useState([])

  const handleSubmitForm =  (values) => {
    changeStateSubmitButton(form, setForm)
    if(Number(localStorage.getItem('reset-pass-max')) >= 5){
        setAlertMessages([t('KEY_FORM_CONTACT_MAX_LIMITED', { ns: 'reset-password' })])
    }else{
        axios.post('/api/user/reset-password', {
            numregistre: values.nrn
        })
            .then(()=>{
                setSuccessMessages(t('_KEY_FORM_CONTACT_MESSAGE_SUCCESS', { ns: 'reset-password' }))
            })
            .catch((err)=>{
                console.log(err.response.data)
                if(err.response.data.error === 'not-found'){
                    localStorage.setItem('reset-pass-max',`${Number(localStorage.getItem('reset-pass-max'))+1}`)
                    setAlertMessages([t('_KEY_FORM_CONTACT_NOT_FOUND_IN_DB', { ns: 'reset-password' })])
                }else{
                    setAlertMessages([t('_KEY_FORM_CONTACT_MESSAGE_GENERAL_ERROR', { ns: 'reset-password' })])
                }
            })
            .finally(
                ()=>{
                    changeStateSubmitButton(form, setForm)
                }
            )
    }
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(
      ()=>{
        if(!localStorage.getItem('reset-pass-max')){
          localStorage.setItem('reset-pass-max','1')
        }
      },[]
  )
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
          {children}
          {onClose ? (
              <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
              >
                <CloseIcon />
              </IconButton>
          ) : null}
        </DialogTitle>
    );
  }
  return(<>
    <Dialog
        fullScreen={false}
        maxWidth={'md'}
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={handleClose}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Trans
            i18nKey="reset-password:_TKEY_SECTION_MODAL_TITLE"
            components={defaultComponent()}
        />
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {
            ( successMessages ) && <SuccessMessage disableReturnButton={true} alertMessages={successMessages} />
        }
          {
              (alertMessages ) && <>
                  {
                      alertMessages.map((message, index) => (
                          <Alert severity="error" key={index} sx={{mb: 3}}>{message}</Alert>
                      ))
                  }
              </>
          }
          {
           (!alertMessages ||!successMessages) &&
              <>
              <Box sx={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: "18px",
                lineHeight: '22px',
              }}>
                <Box my={4}>
                  <Trans
                      i18nKey="reset-password:_TKEY_SECTION_MODAL_CONTENT"
                      components={defaultComponent()}
                  />
                </Box>
                <FormMaker form={form} handleSubmitForm={handleSubmitForm} />
              </Box>
            </>
        }
      </DialogContent>
      <DialogActions>
              <Button variant={"outlined"} sx={{ textTransform:'none',borderRadius: "50px",width: '100px', height:'36px',fontSize: '16px',}} onClick={handleClose} >
                <Trans
                    i18nKey="reset-password:_TKEY_SECTION_MODAL_ACTION"
                    components={defaultComponent()}
                />
              </Button>
      </DialogActions>
    </Dialog>
    <Box onClick={handleClickOpen}>
      <Button sx={{
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '22px',
        textAlign: 'left',
        letterSpacing: '0.05em',
        textTransform:'none',
        px:0
      }}
              color={"inherit"}
      >
        <Box sx={{display:'flex', alignItems:'center',color: 'theme.color.secondary'}}>
         <u>
             <Trans
              i18nKey="reset-password:_TKEY_CONTENT_MODAL_BUTTON"
              components={defaultComponent()}
          />
         </u>
        </Box>
      </Button>
    </Box>
  </>)
}
export  default  DialogNumRegistreReset
