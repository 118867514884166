import  React from 'react'
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import {Button} from "@mui/material";
import {useRouter} from "next/router";
import {useTranslation} from "next-i18next";


const SuccessMessage = ({alertMessages, disableReturnButton=false,messageButton,messageLink})=>{

    const router = useRouter()
    const { t } = useTranslation(['common']);

    return ( <Box sx={{textAlign: 'center'}}>
        <Box sx={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize:' 24px',
            lineHeight: '29px',
            letterSpacing: '0.07em',
            color: '#000000',
            mt:10
        }}>
            {
                alertMessages
            }
        </Box>
        <DoneIcon sx={{ color: '#0CCB36', fontSize:'10rem' }} />
        {
            !disableReturnButton &&  <Box display={"flex"} justifyContent={'center'} sx={{mt:10}}>
                <Button onClick={()=>router.push(messageLink?messageLink:'/')}  variant={'contained'} sx={{  width: '180px', borderRadius: "50px", textTransform:'none'}}>{messageButton?messageButton:t('_KEY_SUCCESS_MESSAGE_TEXT_BUTTON', { ns: 'common' })}</Button>
            </Box>
        }
    </Box>)
}

export  default  SuccessMessage